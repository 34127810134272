// Skills Icons
import awsIcon from "./images/aws.svg";
import reactIcon from "./images/react.svg";
import jsIcon from "./images/javascript.svg";
import designIcon from "./images/design.svg";
import codeIcon from "./images/code.svg";
import analyticsIcon from "./images/business-analytics.svg";
import photo from "./images/me.png";

/** Jobs */
import hisense from "./images/hisense.jpg";
import umbrella from "./images/umbrella.png";

// Social Icon
import linkedInIcon from "./images/linkedin.svg";
import instagramIcon from "./images/instagram.svg";
import tiktokIcon from "./images/tiktok.svg";
import twitchIcon from "./images/twitch.svg";

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Cristopher",
  headerTagline: [
    //Line 1 For Header
    "fullstack developer",
    //Line 2 For Header
    "visual artist",
    //Line 3 For Header
    "cloud enthusiast",
  ],
  //   Header Paragraph
  headerParagraph: "",

  //Contact Email
  contactEmail: "isc.cristopher@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: "PSL Group", //Project Title - Add Your Project Title Here
      para: "Senior Application Developer under the FirstWord project", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/M6R8LMH.png&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://www.pslgroup.com",
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: "Dentist Génesis Cázares", //Project Title - Add Your Project Title Here
      para: "Developing and designing Dentist Dentist Génesis Cázares web page", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/369OAnr.jpeg&auto=format&fit=crop&w=400&q=90",
      //Project URL - Add Your Project Url Here
      url: "https://dentistagenesiscazares.com/",
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      title: "Grupo Tress International", //Project Title - Add Your Project Title Here
      para: "Software development utilizing the AWS Cloud", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://i.imgur.com/65zObT2.png&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://tress.com.mx",
    },
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      title: "Hisense", //Project Title - Add Your Project Title Here
      para: "Developing in-house software for production lines management", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: hisense,
      //Project URL - Add Your Project Url Here
      url: "https://hisense.com.mx",
    },
    {
      id: 5, //DO NOT CHANGE THIS (Please)😅
      title: "Umbrella Seguros", //Project Title - Add Your Project Title Here
      para: "Software developing utilizing Azure Services", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: umbrella,
      //Project URL - Add Your Project Url Here
      url: "https://umbrella-seguros.com",
    },
  ],
  about: {
    paragraphs: [
      {
        id: 1,
        text: `Hi, I'm Cristopher Cervantes, a design and development professional with over nine years of experience across a variety of creative and technical disciplines. I've built a career out of translating innovative ideas into compelling digital solutions.`,
      },
      {
        id: 2,
        text: `I've worked across on-premises and cloud environments, developing applications that solve complex technical challenges. My technical toolkit includes languages like C#, .NET, PHP, Python, NodeJS, and React, allowing me to craft versatile and robust software solutions.`,
      },
      {
        id: 3,
        text: `Instead of focusing only on what I’ve done in the past, I like to keep my eyes on the future. I’m all about learning new things, tackling challenges, and going after goals that truly matter.`,
      },
      {
        id: 4,
        text: `I have an insatiable appetite to keep learning, this drives to me to stay at the forefront of technology and new industry trends. My passion for continuous improvement motivates to search for innovative and projects that have an impact on the world.`,
      },
    ],
    image: photo,
  },
  skills: [
    {
      id: 1,
      img: awsIcon,
      para:
        "With my knowledge of AWS services, we can build easy-to-use and scalable projects that will be ready for testing, builing and delivering them in a continuous and optimized way. By harnessing the power of the cloud we can ensure that your users have consistent access to your latest and most stable versions of your products.",
    },
    {
      id: 2,
      img: codeIcon,
      para:
        "From Lambda all the way to .NET, backend if the foundation of every application. Together let's build a strong and robust framework for your products, ensuring performance and reliability. ",
    },
    {
      id: 3,
      img: reactIcon,
      para:
        "With my React skills, we can build a responsive, modern and stylized interfaces that not only feel and look amazing, but also are easy-to-maintain.",
    },
    {
      id: 4,
      img: designIcon,
      para:
        "From designing captivating web pages, all the way to animating your mascot in a promo video, I possess the expertise to bring your brand to life. Let's work together to create a unique and memorable experience for your users.",
    },
    {
      id: 5,
      img: jsIcon,
      para:
        "If React is not your cup of tea and you solely want to enhance your current website, my expertise with JavaScript and CSS can revitalize your brand, giving your product a fresh new coat of paint.",
    },
    {
      id: 6,
      img: analyticsIcon,
      para:
        "An application is as reliable as the developer who built it. With my experience using web analytics and security tools I can ensure that your application would not only work and look great, it will also be secure, reliable and scalable.",
    },
  ],

  interestsHeading: "Interests",
  interestsPara:
    "I am looking to collaborate along companies agencies and inviduals. Not to simply work for them, but to work with them, bringing my experience to the table. Together we can tackle real business and technological challenges, harnessing our collective expertise and knowledge.",
  // End interests Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create a new experience together!",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: linkedInIcon,
      url: "https://www.linkedin.com/in/thecristopher/",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/thecristopher/",
    },
    {
      img: tiktokIcon,
      url: "https://www.tiktok.com/@thecristopher11",
    },
    {
      img: twitchIcon,
      url: "https://www.twitch.tv/thecristopherofficial",
    },
  ],

  // End Contact Section ---------------
};

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
